//Custom directive
Vue.directive('tooltip', function(el, binding) {
    $(el).tooltip({
        title: binding.value,
        placement: binding.arg,
        trigger: 'hover'
    });

    $(el).on('click', function() {
        $(this).tooltip('hide');
    });
});

Vue.filter('camelToEnglish', el => {
    return el
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function(str){ return str.toUpperCase(); })
})