var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-expand-md shadow-sm",
      attrs: { id: "MainNavbar" },
    },
    [
      _c(
        "a",
        {
          ref: "logo",
          staticClass: "navbar-brand",
          attrs: {
            href: _vm.isCurrentPage("/login")
              ? "https://www.intelligentwellheadsystems.com/"
              : "/home",
          },
        },
        [
          _c("div", { staticClass: "position-relative" }, [
            _vm.isCurrentPage("/login")
              ? _c("img", {
                  attrs: {
                    id: "imgIWSLogo",
                    src: "/images/IWS_Logo_Rev.png",
                    height: "40",
                    alt: "IWSLogo",
                  },
                })
              : _c("img", {
                  attrs: {
                    id: "imgLogo",
                    src: "/images/inVision_rebrand_logo_2021.png",
                    height: "30",
                    alt: "IWS",
                  },
                }),
            _vm._v(" "),
            _vm.isPreProd
              ? _c("div", { staticClass: "preprod_banner_text" }, [
                  _vm._v("\n                 PRE-PROD!\n             "),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      !!_vm.job && !!_vm.job.jobNumber
        ? [
            !_vm._isNullOrEmpty(_vm.filteredJobs)
              ? _c(
                  "span",
                  {
                    class: {
                      "extend-job-select":
                        !_vm.percentCompleted || _vm.percentCompleted <= 0,
                    },
                    attrs: { id: "job-select" },
                  },
                  [
                    _c("iws-select", {
                      attrs: {
                        value: _vm.job.jobNumber,
                        options: _vm.filteredJobs,
                        "display-name": "displayName",
                        "value-name": "jobNumber",
                      },
                      on: { change: _vm.onJobChange },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              { attrs: { id: "stages-completed" } },
              [
                _vm.percentCompleted > 0
                  ? _c("iws-button", {
                      staticStyle: { width: "300px", "margin-left": "15px" },
                      attrs: { type: "outline-light" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                     Stages Completed: " +
                                    _vm._s(_vm.totalCompletedStages) +
                                    "/" +
                                    _vm._s(_vm.numberOfStagesAllWells) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "ml-1 warning-text-color" },
                                  [
                                    _vm._v(
                                      "(" + _vm._s(_vm.percentCompleted) + "%)"
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1566270665
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      !!_vm.user
        ? _c(
            "ul",
            { staticClass: "navbar-nav ml-auto" },
            [
              !!_vm.job && !!_vm.job.jobNumber
                ? [
                    _vm.permissionsMap.Camera
                      ? _c("camera-button", {
                          attrs: { "job-number": _vm.job.jobNumber },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm._isNullOrEmpty(_vm.comms)
                      ? _c("li", [
                          _c(
                            "span",
                            {
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "bottom",
                                title: "SiteComms",
                              },
                            },
                            [
                              _c(
                                "a",
                                [
                                  _c("comms-widget", {
                                    attrs: {
                                      user: _vm.user,
                                      "job-number": _vm.job.jobNumber,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("li", { staticClass: "clickable" }, [
                _c(
                  "span",
                  {
                    attrs: {
                      "data-toggle": "tooltip",
                      "data-placement": "bottom",
                      title: "Home",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: {
                          "active-dropdown-item": _vm.isCurrentPage("/home"),
                        },
                        attrs: { href: "/home" },
                      },
                      [_c("i", { staticClass: "fas fa-home" })]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.reducedWidth
                ? _c("li", { staticClass: "clickable position-relative" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu dropdown-menu-right",
                        attrs: {
                          id: "mobileMenuDropdown",
                          "aria-labelledby": "mobileMenu",
                        },
                      },
                      [
                        !_vm._isNullOrEmpty(_vm.filteredReports)
                          ? [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class: { show: _vm.openTabs.reports },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.openTabs.reports =
                                        !_vm.openTabs.reports
                                    },
                                  },
                                },
                                [
                                  _c("b", [_vm._v("Reports")]),
                                  _vm._v(" "),
                                  _vm._m(1),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.openTabs.reports
                                ? _vm._l(
                                    _vm.filteredReports,
                                    function (reportPage) {
                                      return _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          class: {
                                            "active-dropdown-item":
                                              _vm.isCurrentPage(
                                                reportPage.link
                                              ),
                                          },
                                          staticStyle: {
                                            "padding-left": "35px",
                                          },
                                          attrs: { href: reportPage.link },
                                        },
                                        [
                                          _vm._v(
                                            "\n                             ○ " +
                                              _vm._s(reportPage.title) +
                                              "\n                         "
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm._isNullOrEmpty(_vm.filteredAdmin)
                          ? [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  class: { show: _vm.openTabs.admin },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.openTabs.admin = !_vm.openTabs.admin
                                    },
                                  },
                                },
                                [
                                  _c("b", [_vm._v("Admin")]),
                                  _vm._v(" "),
                                  _vm._m(2),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.openTabs.admin
                                ? _vm._l(
                                    _vm.filteredAdmin,
                                    function (adminPage) {
                                      return _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          class: {
                                            "active-dropdown-item":
                                              _vm.isCurrentPage(adminPage.link),
                                          },
                                          staticStyle: {
                                            "padding-left": "35px",
                                          },
                                          attrs: { href: adminPage.link },
                                        },
                                        [
                                          _vm._v(
                                            "\n                             ○ " +
                                              _vm._s(adminPage.title) +
                                              "\n                         "
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            class: {
                              "active-dropdown-item":
                                _vm.isCurrentPage("/release-notes"),
                            },
                            attrs: { href: "/release-notes" },
                            on: {
                              click: function ($event) {
                                return _vm.clearNewVersionBadge()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                     Release Notes\n                 "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: {
                              "data-toggle": "modal",
                              "data-target": "#supportModal",
                            },
                          },
                          [
                            _vm._v(
                              "\n                     Support\n                 "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            class: {
                              "active-dropdown-item":
                                _vm.isCurrentPage("/account-settings"),
                            },
                            attrs: { href: "/account-settings" },
                          },
                          [
                            _vm._v(
                              "\n                     Profile\n                 "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.novaImpersonatedBy
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/impersonation/stop" },
                              },
                              [
                                _vm._v(
                                  "\n                     Stop Impersonation\n                 "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item clickable",
                            on: { click: _vm.logout },
                          },
                          [
                            _vm._v(
                              "\n                     Logout\n                 "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ])
                : [
                    !_vm._isNullOrEmpty(_vm.filteredReports)
                      ? _c(
                          "li",
                          { staticClass: "clickable position-relative" },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      "active-dropdown-item":
                                        _vm.reportPageIsActive,
                                    },
                                    attrs: {
                                      id: "reportsMenu",
                                      "data-toggle": "tooltip",
                                      "data-placement": "bottom",
                                      title: "Reports",
                                    },
                                  },
                                  [_c("i", { staticClass: "far fa-file-alt" })]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                                attrs: { "aria-labelledby": "reportsMenu" },
                              },
                              _vm._l(
                                _vm.filteredReports,
                                function (reportPage) {
                                  return _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      class: {
                                        "active-dropdown-item":
                                          _vm.isCurrentPage(reportPage.link),
                                      },
                                      attrs: { href: reportPage.link },
                                    },
                                    [
                                      _vm._v(
                                        "\n                         " +
                                          _vm._s(reportPage.title) +
                                          "\n                     "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm._isNullOrEmpty(_vm.filteredAdmin)
                      ? _c(
                          "li",
                          { staticClass: "clickable position-relative" },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      "active-dropdown-item":
                                        _vm.isCurrentPage("/release-notes"),
                                    },
                                    attrs: {
                                      id: "adminMenu",
                                      "data-toggle": "tooltip",
                                      "data-placement": "bottom",
                                      title: "Support",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-question-circle",
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticStyle: { display: "none" },
                                      attrs: { id: "newVersionNotification" },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                                attrs: { "aria-labelledby": "adminMenu" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    class: {
                                      "active-dropdown-item":
                                        _vm.isCurrentPage("/release-notes"),
                                    },
                                    attrs: { href: "/release-notes" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearNewVersionBadge()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticStyle: {
                                        color: "red",
                                        "vertical-align": "top",
                                        "font-size": "0.75rem",
                                      },
                                      attrs: { id: "newVersionBadge" },
                                    }),
                                    _vm._v(
                                      "\n\n                         Release Notes\n                     "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": "#supportModal",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                         Support\n                     "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm._isNullOrEmpty(_vm.filteredAdmin)
                      ? _c(
                          "li",
                          { staticClass: "clickable position-relative" },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      "active-dropdown-item":
                                        _vm.adminPageIsActive,
                                    },
                                    attrs: {
                                      id: "adminMenu",
                                      "data-toggle": "tooltip",
                                      "data-placement": "bottom",
                                      title: "Admin",
                                    },
                                  },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                                attrs: { "aria-labelledby": "adminMenu" },
                              },
                              _vm._l(_vm.filteredAdmin, function (adminPage) {
                                return _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    class: {
                                      "active-dropdown-item": _vm.isCurrentPage(
                                        adminPage.link
                                      ),
                                    },
                                    attrs: { href: adminPage.link },
                                  },
                                  [
                                    _vm._v(
                                      "\n                         " +
                                        _vm._s(adminPage.title) +
                                        "\n                     "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", { staticClass: "position-relative" }, [
                      _c(
                        "span",
                        {
                          attrs: {
                            id: "userMenu",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "bottom",
                                title: "Profile",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "circle clickable",
                                  style: `background-color: ${_vm.colour(
                                    _vm.email
                                  )}`,
                                },
                                [
                                  _vm._v(
                                    "\n                             " +
                                      _vm._s(_vm.fullUserName) +
                                      "\n                         "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu dropdown-menu-right",
                          attrs: { "aria-labelledby": "userMenu" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              class: {
                                "active-dropdown-item":
                                  _vm.isCurrentPage("/account-settings"),
                              },
                              attrs: { href: "/account-settings" },
                            },
                            [
                              _vm._v(
                                "\n                         Profile\n                     "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.novaImpersonatedBy
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/impersonation/stop" },
                                },
                                [
                                  _vm._v(
                                    "\n                         Stop Impersonation\n                     "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item clickable",
                              on: { click: _vm.logout },
                            },
                            [
                              _vm._v(
                                "\n                         Logout\n                     "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
            ],
            2
          )
        : _c("ul", { staticClass: "navbar-nav ml-auto" }, [_vm._m(3)]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        attrs: {
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [
        _c("a", { attrs: { id: "mobileMenu" } }, [
          _c("i", { staticClass: "fas fa-bars" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticStyle: { float: "right" } }, [
      _c("i", { staticClass: "fas fa-chevron-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticStyle: { float: "right" } }, [
      _c("i", { staticClass: "fas fa-chevron-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      {
        staticClass: "clickable",
        attrs: { "data-toggle": "modal", "data-target": "#supportModal" },
      },
      [_c("a", [_c("i", { staticClass: "fas fa-question-circle" })])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }